<template>
  <div class="daxxiao">
    <div class="box">
      <div class="header" ref="header">
          <img src="../assets/download/header.png" alt="" />
          <img src="../assets/download/logo.png" alt="" class="logo" />
      </div>
      <div class="navigation" ref="navigation">
        <ul>
          <li @click="Jumpindex((Jumplenght = 1))">
            品牌故事
          </li>
          <li
            :class="aktiveindex == 5 ? 'aktive' : ''"
            @click="Jumpindex((Jumplenght = 5))"
          >
            动态
          </li>
          <li @click="Jumpindex((Jumplenght = 2))">
            领养
          </li>
          <li @click="Jumpindex((Jumplenght = 3))">
            喂养
          </li>
          <li @click="Jumpindex((Jumplenght = 4))">
            屠宰
          </li>
        </ul>
      </div>
      <!-- <div style="height: 83px;"></div> -->
      <div class="navigation fled" v-if="scrollshow">
        <ul>
          <li @click="Jumpindex((Jumplenght = 1))">
            品牌故事
          </li>
          <li
            :class="aktiveindex == 5 ? 'aktive' : ''"
            @click="Jumpindex((Jumplenght = 5))"
          >
            动态
          </li>
          <li @click="Jumpindex((Jumplenght = 2))">
            领养
          </li>
          <li @click="Jumpindex((Jumplenght = 3))">
            喂养
          </li>
          <li @click="Jumpindex((Jumplenght = 4))">
            屠宰
          </li>
        </ul>
      </div>
      <div class="buttomcolor dynamic" v-if="aktiveindex == 5">
        <div class="dynamic-box frame">
          <div class="content" v-html="fuwenb"> </div>
        </div>
      </div>
      <div class="footer">
        <cooter></cooter>
      </div>
      <div class="but-jia"></div>
      <div class="but-xiazai" @click="clickBtn">
        立即下载
      </div>
      <van-popup v-model="show">
        <div class="bossBox">
          <p class="verify">手机号绑定验证</p>
          <div>
            <van-cell-group>
              <van-field
                v-model="phone"
                clearable
                :border="false"
                type="number"
                maxlength="11"
                placeholder="请填写手机号"
                left-icon="http://static.yhaocang.com/img/pages/pc/phone.png"
              />
            </van-cell-group>
          </div>
          <div class="yanzheng">
            <van-cell-group class="cell">
              <van-field
                v-model="code"
                :border="false"
                placeholder="验证码"
                left-icon="http://static.yhaocang.com/img/pages/pc/ma.png"
              />
            </van-cell-group>
            <div v-show="codeShow" class="song" @click="getCode">
              获取验证码
            </div>
            <div v-show="!codeShow" class="song">
              {{ yzmTitle }}
            </div>
          </div>
          <div class="sure" @click="isTrue">下载</div>
        </div>
      </van-popup>
      <p class="titile" v-show="titleShow">{{ msg }}</p>
      <div v-if="isweixin" class="isWeixinOpen">
        <img src="../assets/img/zao.png" alt="" />
      </div>
      <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import { getDownUrl, sendSms, bindFriend, trendfo } from '../api/index'
import cooter from '../components/cooter.vue'
export default {
  components: {
    't-captcha-js': {
      render(createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    },
    cooter
  },
  data() {
    return {
      scroll: '0',
      scrollshow: false,
      header: '',
      navigation: '',
      num: '',
      imgindex: 1,
      lenght: 0,
      prevs: 1,
      aktiveindex: 5,
      show: false,
      phone: '',
      msg: '', //提示
      code: '',
      yzmTitle: '',
      titleShow: false,
      codeShow: true, //图片验证
      user: '',
      isweixin: false,
      dialogShow: false, //提示
      index: 1,
      message: '',
      isios: false,
      isandriod: false,
      andriod: '',
      ios: '',
      fuwenb: ''
    }
  },
  created() {
    this.appSource()
    this.trendinfo()
  },
  mounted() {
    if (this.$route.query.type == 0) {
      console.log(123333)
      this.index = 0
    } else {
      this.index = 1
    }

    window.addEventListener('scroll', this.scrollDs, true)
  },
  watch: {
    scroll(val) {
      this.header = this.$refs.header.offsetHeight // 头部高度
      this.navigation = this.$refs.navigation.offsetHeight // 头部高度

      this.num = this.header + this.navigation + 50
      if (this.scroll > this.num) {
        this.scrollshow = true
      } else if (this.scroll <= this.num) {
        this.scrollshow = false
      }
    }
  },
  methods: {
    Jumpindex(typeindex) {
      console.log(typeindex)
      this.$router.push({
        path: '/',
        query: { typeindex: typeindex }
      })
    },
    async trendinfo() {
      let res = await trendfo({ id: this.$route.query.xqid })
      console.log(res, '数据999')
      this.fuwenb = res.data.data.content
      console.log(this.fuwenb, '富文本')
    },
    scrollDs: function () {
      // 页面指定了DTD，即指定了DOCTYPE时，使用document.documentElement。
      // 页面没有DTD，即没指定DOCTYPE时，使用document.body。
      this.scroll =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      // console.log(this.scroll)
    },
    // 剪贴板
    dataProcessing(val) {
      // this.message = '#' + val + '#'
      this.message = val
    },
    // 剪贴板
    doCopy(val) {
      this.dataProcessing(val)
      this.$copyText(this.message).then(
        function (e) {
          console.log(e, 9999)
          console.log(e.text, 9999)
        },
        function (e) {
          console.log(e, 8888)
          console.log(e.text, 888888)
        }
      )
    },
    //
    async appSource() {
      let res = await getDownUrl({
        planform: this.$route.query.planform ? this.$route.query.planform : ''
      })
      if (res.data.code != 200) return
      this.andriod = res.data.data.andriod_url
      this.ios = res.data.data.ios_url
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        this.isios = true
      } else {
        this.isandriod = true
      }
    },
    async clickBtn() {
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') != -1
      if (isWeixin) return (this.isweixin = true)

      if (this.$route.query.invite_code) {
        await this.doCopy(this.$route.query.invite_code)
      }
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (this.$route.query.rid != '' && this.$route.query.rid != undefined) {
        console.log(123)
        console.log(this.$route.query.rid, this.$route.query.store_id)

        if (isiOS) {
          // this.dialogShow = true // 若是ios 做操作
          this.show = true // 若是ios 做操作
        } else {
          this.show = true // 要不就是Android
        }
      } else if (
        this.$route.query.store_id != '' &&
        this.$route.query.store_id != undefined &&
        this.$route.query.store_id != 1
      ) {
        if (isiOS) {
          // this.dialogShow = true // 若是ios 做操作
          this.show = true // 若是ios 做操作
        } else {
          this.show = true // 要不就是Android
        }
      } else if (this.$route.query.invite_code) {
        console.log(456)

        if (this.isios) {
          console.log(this.ios) //ios
          window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
        }
        if (this.isandriod) {
          console.log(this.andriod) //andriod
          window.location.href = this.andriod
        }
      } else {
        if (this.isios) {
          console.log(this.ios) //ios
          // window.open(this.ios)
          window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
        }
        if (this.isandriod) {
          console.log(this.andriod) //andriod
          // window.open(this.andriod)
          window.location.href = this.andriod
        }
      }
    },
    async isTrue() {
      console.log('确定')

      let data = {
        rid: this.$route.query.rid || '',
        uid: this.$route.query.uid,
        phone: this.phone,
        keyCode: this.code,
        store_id: this.$route.query.store_id
      }
      let res = await bindFriend(data)
      console.log(res, 'resres')
      if (res.data.code != 200) {
        this.msg = res.data.message
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      if (this.isios) {
        console.log(this.ios) //ios
        // window.open(this.ios)
        window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
      }
      if (this.isandriod) {
        console.log(this.andriod) //andriod
        // window.open(this.andriod)
        window.location.href = this.andriod
      }
      this.show = !this.show
    },
    getCode() {
      if (!this.phone) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (!reg.test(this.phone)) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      this.showCodeImg()
    },
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha('2008794111', (coderes) => {
        console.log(coderes)
        const { randstr, ticket } = coderes
        sendSms({ phone: this.phone, type1: 1, randstr, ticket }).then(
          (res) => {
            console.log(res)
            if (res.data.code == 200) {
              let time = 60
              let timer = setInterval(() => {
                if (time == 0) {
                  clearInterval(timer)
                  this.codeShow = true
                } else {
                  this.codeShow = false
                  this.yzmTitle = time + '秒后重试'
                  time--
                }
              }, 1000)
              this.msg = '获取验证码成功'
              console.log(this.msg, 'this.msg')
              this.titleShow = true
              setTimeout(() => {
                this.titleShow = false
              }, 2000)
            } else {
              this.$toast(res.data.message)
            }
          }
        )
      })
      captcha1.show()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .box {
    width: 100vw;
    height: 100vh;
    .frame {
      border: 3.5px solid #58340c;
      box-sizing: border-box;
    }
    .header {
      width: 100vw;
      height: 480px;
      // background-image: url('../assets/download/header.png');
      // background-repeat: no-repeat;
      // background-size: 100%;
      img {
        width: 100%;
        height: 100%;
      }
      .logo {
        position: absolute;
        left: 28px;
        top: 39px;
        width: 228px;
        height: 80px;
      }
    }
    .fled {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000000 !important;
    }
    .navigation {
      width: 100vw;
      height: 83px;
      background: #01ce69;
      padding: 0 47px;
      box-sizing: border-box;
      // display: flex;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
      }
      li {
        flex: 1;
        height: 100%;
        // background: #f1ff10;
        font-size: 20.8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .aktive {
        background: #f1ff10;
      }
    }
    .buttomcolor {
      background: #6bd09e;
    }
    .ppgsbuttom {
      padding: 43.75px 0 0 0;
      box-sizing: border-box;
      .ppgs-viope-box {
        width: 638px;
        height: 359px;
        // background: #000;
        margin: 0 auto;
        margin-bottom: 67px;

        /deep/ #myplayer {
          width: 100%;
          height: 100%;
        }
      }
      .pig-table {
        margin: 0 auto;
        width: 584px;
        height: 60px;
        background: #f1ff10;
        line-height: 60px;
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 25px;
        color: #035a5c;
        margin-bottom: 81.25px;
      }
      .fangxinchi {
        width: 100vw;
        height: 408px;
        text-align: center;
        margin-bottom: 89.58px;
        img {
          width: 679px;
          height: 408px;
        }
      }
      .fangxinchi-box {
        margin: 0 auto;
        width: 679px;
        height: 408px;
        text-align: center;
        margin-bottom: 89.58px;
        background-image: url('../assets/download/yangzufx.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 110px 60px 0 48px;
        box-sizing: border-box;
        .duizhong {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            width: 110px;
            height: 222px;
            // border: 3px solid #035a5c;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            text-align: left;
            width: 418px;
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #6a4a20;
            line-height: 38px;
          }
        }
      }
      .linian-box {
        width: 100vw;
        height: 1061.8px;
        text-align: center;
        margin-bottom: 126px;
        img {
          width: 717.36px;
          height: 1061.8px;
        }
      }
      .ppgs-buttom-box {
        // width: 717.36px;
        width: 690px;
        height: 1061.8px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 126px;
        padding-top: 18.75px;
        box-sizing: border-box;
        background: #ffffff;
        .title-lit {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 2px;
        }
        /deep/ .title {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 24px;
        }
        .text {
          width: 248px;
          // height: 221px;
          font-size: 21px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 58px;
          .img-box-left {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-left: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-box-right {
            text-align: left;
            padding-right: 10px;
            box-sizing: border-box;
          }
        }
        .two {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 88px;

          .img-box-left {
            text-align: left;
            padding-left: 10px;
            box-sizing: border-box;
          }
          .text-box-right {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-right: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .dianji-img-box {
        width: 100vw;
        height: 457px;
        text-align: center;
        margin-bottom: 16px;
        img {
          width: 645px;
          height: 457px;
        }
      }
      .dianji-img-six {
        width: 645px;
        height: 70px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 120px;
        padding-bottom: 50px;
        // box-sizing: border-box;
        img {
          width: 96px;
          height: 70px;
        }
        .aktive {
          background: #ffffff;
          border: 4px solid #ffffff;
        }
      }
      .ppgsfooter {
        width: 100vw;
        height: 333.33px;
        background: #2e2e2e;
        // text-align: center;
        // line-height: 333px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          width: 660px;
          font-size: 26.6px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff !important;
          margin-bottom: 10px;
          // margin-left: 73px;
        }
      }
    }
    .spin_img {
      // width: 100%;
      // height: 100%;
      // width: 200px;
      // height: 300px;
      width: 361px;
      height: 486px;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-slide {
      width: 361px !important;
      height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-slider {
      width: 361px !important;
      height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .next {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/next.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    ::v-deep .prev {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/prev.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    .lybuttom {
      padding: 79px 0 76px 0;
      box-sizing: border-box;
      .swipc-box-3d {
        width: 692px;
        margin: 0 auto;
        margin-bottom: 64px;
      }
      .lingyang-m-b {
        margin-bottom: 74px !important;
      }
      .lingyang {
        width: 100vw;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 58px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }

      .ly-list {
        width: 100vw;
        height: 850.69px;
        text-align: center;
        margin-bottom: 65px;
        img {
          width: 692px;
          height: 850.69px;
        }
      }
      .ly-list-box {
        // width: 692px;
        // height: 850.69px;
        width: 685px;
        height: 843.69px;
        text-align: center;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 42px 23px 0 18px;
        box-sizing: border-box;
        margin-bottom: 51px;
        .title {
          display: flex;
          align-content: center;
          justify-content: center;
          margin-bottom: 61px;
          span {
            font-size: 33.33px;
            font-family: HYHanHeiW;
            font-weight: 700;
            color: #37b96f;
            margin-right: 18px;
          }
          img {
            width: 28.47px;
            height: 50.69px;
          }
        }
        .img-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          .tu-1 {
            width: 400px;
            height: 227px;
          }
          .tu-2 {
            width: 229px;
            height: 227px;
          }
          .tu-3 {
            width: 265px;
            height: 149px;
          }
          .tu-4 {
            width: 175px;
            height: 149px;
          }
          .tu-5 {
            width: 178px;
            height: 149px;
          }
        }
        .text-box {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            // width: 312px;
            // height: 209px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #644110;
            text-align: left;
          }
          img {
            width: 275px;
            height: 206.9px;
            margin-left: 12px;
          }
        }
      }
      .lc-list {
        // width: 100vw;
        height: 325px;
        // padding: 0 21px;
        width: 699px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        img {
          width: 217.36px;
          height: 325px;
        }
      }
      .lc-m-b {
        margin-bottom: 64px;
      }
      .ly-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/ly-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .wybuttom {
      padding: 40px 0 72px 0;
      box-sizing: border-box;
      .wy-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/wy-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        margin-bottom: 52px;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
      .wy-swipe-box {
        text-align: center;
        margin: 0 auto;
        width: 682px;
        height: 582px;
        background-image: url('../assets/download/wy-swpile.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        // margin-bottom: 91px;
        padding: 54px 101px 0 81.25px;
        margin-bottom: 112px;
        box-sizing: border-box;
        position: relative;
        .text-box {
          width: 492px;
          min-height: 146px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          text-align: left;
          margin-bottom: 23px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        .zuo {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          left: 40px;
          top: 336px;
          // left: -30px;
          // top: 0px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .you {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          right: 52px;
          top: 336px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .custom-indicator {
          margin: 0 auto;
          width: 153px;
          height: 12px;
          background: #cdf0d7;
          border-radius: 8px;
          margin-top: 20px;
          display: flex;
          .lu {
            // flex: 1;
            width: 51px;
            height: 12px;
            background: #00b534;
            border-radius: 8px;
          }
          .bai {
            width: 51px;
            height: 12px;
            border-radius: 8px;
          }
        }
        .swipe-box {
          // position: relative;

          width: 500px;
          height: 281.25px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tuandui {
        width: 100vw;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 68.75px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .list-m-b {
        margin-bottom: 93px !important;
      }
      .tuandui-list {
        width: 100vw;
        height: 315px;
        text-align: center;
        margin-bottom: 34px;
        img {
          width: 676px;
          height: 315px;
        }
      }
      .tuandui-list-box {
        // width: 676px;
        width: 669px;
        // height: 315px;
        height: 308px;

        text-align: center;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 21px 0 14px;
        // padding: 0 16px 0 14px;
        box-sizing: border-box;
        .title {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 37.5px;
        }
        .title-1 {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 13.8px;
        }
        .text {
          // width: 207px;
          // height: 76px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          margin-bottom: 10px;
        }
        .text-1 {
          width: 234px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        img {
          width: 381px;
          height: 264px;
        }
        .right {
          width: 234px;
          text-align: left;
        }
      }
      .hougu-list {
        width: 100vw;
        height: 740px;
        text-align: center;
        img {
          width: 713px;
          height: 740px;
        }
      }
      .hougu-list-box {
        // width: 713px;
        // height: 740px;
        width: 706px;
        height: 733px;
        margin: 0 auto;
        background-color: #ffffff;
        padding-top: 22px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 400;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .text {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        .weiya-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 107px;

          .img-1 {
            width: 445px;
            height: 252px;
            padding-left: 15px;
            box-sizing: border-box;
          }
          .img-2 {
            width: 454px;
            height: 272px;
            padding-right: 14px;
            box-sizing: border-box;
          }
          .left {
            width: 220px;
            text-align: left;
            padding-left: 28px;
            box-sizing: border-box;
          }
          .right {
            width: 220px;
            text-align: left;
            padding-right: 22px;
            box-sizing: border-box;
          }
        }
      }
    }
    .tzbuttom {
      background: #6bd09e;
      padding-top: 86px;
      padding-bottom: 160px;
      .slaughter-top {
        width: 100vw;
        height: 900px;
        text-align: center;
        margin-bottom: 57px;
        img {
          width: 713px;
          height: 900px;
        }
      }
      .slaughter-top-box {
        // width: 713px;
        // height: 920px;
        width: 706px;
        height: 913px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        padding: 39px 20px 0 15px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 27px;
        }
        .text {
          // width: 646px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
          text-align: left;
          margin: 0 auto;
          margin-bottom: 36px;
        }
        .fd-img {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 21px;

          img {
            width: 332px;
            height: 252px;
          }
        }
        .tecenter {
          width: 666.66px;
          height: 377px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .slaughter-title {
        width: 100vw;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 57px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .slaughter-buttom {
        width: 100vw;
        height: 3245px;
        text-align: center;

        // padding: 0 21px;
        // box-sizing: border-box;
        margin-bottom: 57px;
        img {
          width: 710px;
          height: 3245px;
        }
      }
      .slaughter-buttom-box {
        // width: 710px;
        // min-height: 3245px;
        width: 707px;
        min-height: 3238px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        // padding-top: 25px;
        padding: 25px 13px 31px 13px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .title-mb {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 12px;
        }
        .text {
          // width: 230px;
          // width: 220px;
          width: 200px;
          // height: 187.5px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        // .text-1 {
        //   padding-right: 25px;
        //   box-sizing: border-box;
        // }
        // .text-2 {
        //   padding-left: 20px;
        //   box-sizing: border-box;
        // }
        .tupian-1 {
          width: 445px;
          height: 252px;
          // padding-left: 13.8px;
          // box-sizing: border-box;
        }
        .tupian-2 {
          width: 454px;
          height: 270px;
          // padding-right: 15px;
          // box-sizing: border-box;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 111px;
          .teleft {
            text-align: left;
          }
        }
      }
    }

    .dynamic {
      overflow: hidden;
      background: #6bd09e;
      padding-top: 45px;
      padding-bottom: 112px;
      .dynamic-box {
        width: 694px;
        min-height: 402px;
        margin: 0 auto;
        background: #ffffff;
        padding: 20px 34px 10px 20px;
        box-sizing: border-box;
        font-size: 20px;
        overflow: hidden;
      }

      .content{

        /deep/ img {
            width: 100%;
          }
      }
    }
    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      // line-height: 150px;
      width: 500px;
      height: 281.25px;
      // width: 100%;
      // height: 100%;
      text-align: center;
      background-color: #39a9ed;
    }
    .isWeixinOpen {
      width: 100vw;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .but-jia {
      width: 100vw;
      height: 91px;
    }
    .but-xiazai {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 91px;
      background: #f1ff10;
      border-top: 3px solid #32b76a;

      font-size: 41px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #048145;
      text-align: center;
      line-height: 91px;
    }
    .logoImg {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        width: 2.2rem;
        object-fit: contain;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6.7rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .bossBox {
      width: 8rem;
      height: 6.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      .verify {
        font-size: 0.53rem;
        color: #333;
        font-weight: 400;
        padding: 0.8rem 0 0.25rem 0;
      }
      .yanzheng {
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .song {
          width: 2.76rem;
          height: 1.15rem;
          background: #43d227;
          border-radius: 0.1rem;
          margin-top: 0.2rem;
          font-size: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fefefe;
          font-weight: 400;
        }
      }
      .sure {
        width: 4.6rem;
        height: 1.1rem;
        background: #43d227;
        font-size: 0.45rem;
        color: #fefefe;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        margin-top: 0.4rem;
        border-radius: 2rem;
      }
    }
    .van-cell-group {
      margin-top: 0.2rem;
      width: 7rem;
      .van-cell {
        height: 1.2rem;
        display: flex;
        align-items: center;
        background: #f2f2f2;
        border-radius: 0.15rem;
        overflow: hidden;
      }
      /deep/ .van-icon {
        font-size: 0.8rem;
        margin: 0 0.3rem 0 0.34rem;
      }

      /deep/ .van-field__body {
        font-size: 0.41rem;
      }
    }
    .cell {
      width: 4rem;
      /deep/ .van-icon {
        font-size: 0.65rem;
        margin: 0 0.3rem 0 0.41rem;
      }
    }
    .titile {
      font-size: 0.4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(#000, 0.4);
      padding: 0.2rem 0.5rem;
      color: #fff;
      border-radius: 0.2rem;
      z-index: 999999;
    }
    .isWeixinOpen {
      width: 100vw;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .van-dialog {
      width: 6rem;
      height: 3.5rem;
      /deep/ .van-hairline--top {
        height: 0.9rem;
        margin-top: 0.6rem;
      }
      /deep/ .van-button {
        height: 100%;
      }
      .titleInfo {
        margin: 0.4rem 0 0.1rem;
      }
      P {
        width: 100%;
        text-align: center;
        font-size: 0.34rem;
        margin-top: 0.1rem;
      }
    }
  }
}
@media screen and(min-width: 901px) and(max-width: 3000px) {
  // .daxxiao {
  //   // width: 100%;
  //   // height: 100%;
  //   width: 100vw;
  //   height: 100vh;
  // }
  .box {
    width: 1000px;
    // height: 100vh;
    margin: 0 auto;
    .frame {
      border: 3.5px solid #58340c;
      box-sizing: border-box;
    }
    .header {
      width: 1000px;
      height: 580px;
      // height: 600px;
      // background-image: url('../assets/download/header.png');
      // background-repeat: no-repeat;
      // background-size: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        // position: relative;
      }
      .logo {
        position: absolute;
        left: 28px;
        top: 39px;
        width: 228px;
        height: 80px;
      }
    }
    .fled {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000000 !important;
      width: 1000px;
    }
    .navigation {
      // width: 100vw;
      width: 1000px;
      height: 83px;
      background: #01ce69;
      padding: 0 47px;
      box-sizing: border-box;
      // display: flex;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
      }
      li {
        flex: 1;
        height: 100%;
        // background: #f1ff10;
        font-size: 20.8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .aktive {
        background: #f1ff10;
      }
    }
    .buttomcolor {
      background: #6bd09e;
    }
    .ppgsbuttom {
      padding: 43.75px 0 0 0;
      box-sizing: border-box;
      .ppgs-viope-box {
        width: 638px;
        height: 359px;
        // background: #000;
        margin: 0 auto;
        margin-bottom: 67px;

        /deep/ #myplayer {
          width: 100%;
          height: 100%;
        }
      }
      .pig-table {
        margin: 0 auto;
        width: 584px;
        height: 60px;
        background: #f1ff10;
        line-height: 60px;
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 25px;
        color: #035a5c;
        margin-bottom: 81.25px;
      }
      // .fangxinchi {
      //   width: 100vw;
      //   height: 408px;
      //   text-align: center;
      //   margin-bottom: 89.58px;
      //   img {
      //     width: 679px;
      //     height: 408px;
      //   }
      // }
      .fangxinchi-box {
        margin: 0 auto;
        width: 679px;
        height: 408px;
        text-align: center;
        margin-bottom: 89.58px;
        background-image: url('../assets/download/yangzufx.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 110px 60px 0 48px;
        box-sizing: border-box;
        .duizhong {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            width: 110px;
            height: 222px;
            // border: 3px solid #035a5c;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            text-align: left;
            width: 418px;
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #6a4a20;
            line-height: 38px;
          }
        }
      }
      .linian-box {
        // width: 100vw;
        width: 1000px;
        height: 1061.8px;
        text-align: center;
        margin-bottom: 126px;
        img {
          width: 717.36px;
          height: 1061.8px;
        }
      }
      .ppgs-buttom-box {
        // width: 717.36px;
        width: 690px;
        height: 1061.8px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 126px;
        padding-top: 18.75px;
        box-sizing: border-box;
        background: #ffffff;
        .title-lit {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 2px;
        }
        /deep/ .title {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 24px;
        }
        .text {
          width: 248px;
          // height: 221px;
          font-size: 21px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 58px;
          .img-box-left {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-left: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-box-right {
            text-align: left;
            padding-right: 10px;
            box-sizing: border-box;
          }
        }
        .two {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 88px;

          .img-box-left {
            text-align: left;
            padding-left: 10px;
            box-sizing: border-box;
          }
          .text-box-right {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-right: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .dianji-img-box {
        // width: 100vw;
        width: 1000px;
        height: 457px;
        text-align: center;
        margin-bottom: 16px;
        img {
          width: 645px;
          height: 457px;
        }
      }
      .dianji-img-six {
        width: 645px;
        height: 70px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 120px;
        padding-bottom: 50px;
        // box-sizing: border-box;
        img {
          width: 96px;
          height: 70px;
        }
        .aktive {
          background: #ffffff;
          border: 4px solid #ffffff;
        }
      }
      .ppgsfooter {
        // width: 100vw;
        width: 1000px;
        height: 333.33px;
        background: #2e2e2e;
        // text-align: center;
        // line-height: 333px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          width: 660px;
          font-size: 26.6px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff !important;
          margin-bottom: 10px;
          // margin-left: 73px;
        }
      }
    }
    .spin_img {
      // width: 100%;
      // height: 100%;
      // width: 200px;
      // height: 300px;
      width: 361px;
      min-height: 486px;
      // height: 586px;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-container {
      min-height: 486px !important;
    }
    ::v-deep .carousel-3d-slide {
      width: 361px !important;
      min-height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-slider {
      width: 361px !important;
      min-height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .next {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/next.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    ::v-deep .prev {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/prev.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    .lybuttom {
      padding: 79px 0 76px 0;
      box-sizing: border-box;
      .swipc-box-3d {
        width: 692px;
        margin: 0 auto;
        margin-bottom: 64px;
        // min-height: 500px;
      }
      .lingyang-m-b {
        margin-bottom: 74px !important;
      }
      .lingyang {
        // width: 100vw;
        width: 1000px;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 58px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }

      .ly-list {
        // width: 100vw;
        width: 1000px;
        height: 850.69px;
        text-align: center;
        margin-bottom: 65px;
        img {
          width: 692px;
          height: 850.69px;
        }
      }
      .ly-list-box {
        // width: 692px;
        // height: 850.69px;
        width: 685px;
        height: 843.69px;
        text-align: center;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 42px 23px 0 18px;
        box-sizing: border-box;
        margin-bottom: 51px;
        .title {
          display: flex;
          align-content: center;
          justify-content: center;
          margin-bottom: 61px;
          span {
            font-size: 33.33px;
            font-family: HYHanHeiW;
            font-weight: 700;
            color: #37b96f;
            margin-right: 18px;
          }
          img {
            width: 28.47px;
            height: 50.69px;
          }
        }
        .img-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          .tu-1 {
            width: 400px;
            height: 227px;
          }
          .tu-2 {
            width: 229px;
            height: 227px;
          }
          .tu-3 {
            width: 265px;
            height: 149px;
          }
          .tu-4 {
            width: 175px;
            height: 149px;
          }
          .tu-5 {
            width: 178px;
            height: 149px;
          }
        }
        .text-box {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            // width: 312px;
            // height: 209px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #644110;
            text-align: left;
          }
          img {
            width: 275px;
            height: 206.9px;
            margin-left: 12px;
          }
        }
      }
      .lc-list {
        // width: 100vw;
        height: 325px;
        // padding: 0 21px;
        width: 699px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        img {
          width: 217.36px;
          height: 325px;
        }
      }
      .lc-m-b {
        margin-bottom: 64px;
      }
      .ly-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/ly-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .wybuttom {
      padding: 40px 0 72px 0;
      box-sizing: border-box;
      .wy-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/wy-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        margin-bottom: 52px;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
      .wy-swipe-box {
        text-align: center;
        margin: 0 auto;
        width: 682px;
        height: 582px;
        background-image: url('../assets/download/wy-swpile.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        // margin-bottom: 91px;
        padding: 54px 101px 0 81.25px;
        margin-bottom: 112px;
        box-sizing: border-box;
        position: relative;
        .text-box {
          width: 492px;
          min-height: 146px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          text-align: left;
          margin-bottom: 23px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        .zuo {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          left: 40px;
          top: 336px;
          // left: -30px;
          // top: 0px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .you {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          right: 52px;
          top: 336px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .custom-indicator {
          margin: 0 auto;
          width: 153px;
          height: 12px;
          background: #cdf0d7;
          border-radius: 8px;
          margin-top: 20px;
          display: flex;
          .lu {
            // flex: 1;
            width: 51px;
            height: 12px;
            background: #00b534;
            border-radius: 8px;
          }
          .bai {
            width: 51px;
            height: 12px;
            border-radius: 8px;
          }
        }
        .swipe-box {
          // position: relative;

          width: 500px;
          height: 281.25px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tuandui {
        // width: 100vw;
        width: 1000px;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 68.75px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .list-m-b {
        margin-bottom: 93px !important;
      }
      .tuandui-list {
        // width: 100vw;
        width: 1000px;
        height: 315px;
        text-align: center;
        margin-bottom: 34px;
        img {
          width: 676px;
          height: 315px;
        }
      }
      .tuandui-list-box {
        // width: 676px;
        width: 669px;
        // height: 315px;
        height: 308px;

        text-align: center;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 21px 0 14px;
        // padding: 0 16px 0 14px;
        box-sizing: border-box;
        .title {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 37.5px;
        }
        .title-1 {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 13.8px;
        }
        .text {
          // width: 207px;
          // height: 76px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          margin-bottom: 10px;
        }
        .text-1 {
          width: 234px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        img {
          width: 381px;
          height: 264px;
        }
        .right {
          width: 234px;
          text-align: left;
        }
      }
      .hougu-list {
        // width: 100vw;
        width: 1000px;
        height: 740px;
        text-align: center;
        img {
          width: 713px;
          height: 740px;
        }
      }
      .hougu-list-box {
        // width: 713px;
        // height: 740px;
        width: 706px;
        height: 733px;
        margin: 0 auto;
        background-color: #ffffff;
        padding-top: 22px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 400;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .text {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        .weiya-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 107px;

          .img-1 {
            width: 445px;
            height: 252px;
            padding-left: 15px;
            box-sizing: border-box;
          }
          .img-2 {
            width: 454px;
            height: 272px;
            padding-right: 14px;
            box-sizing: border-box;
          }
          .left {
            width: 220px;
            text-align: left;
            padding-left: 28px;
            box-sizing: border-box;
          }
          .right {
            width: 220px;
            text-align: left;
            padding-right: 22px;
            box-sizing: border-box;
          }
        }
      }
    }
    .tzbuttom {
      background: #6bd09e;
      padding-top: 86px;
      padding-bottom: 160px;
      .slaughter-top {
        // width: 100vw;
        width: 1000px;
        height: 900px;
        text-align: center;
        margin-bottom: 57px;
        img {
          width: 713px;
          height: 900px;
        }
      }
      .slaughter-top-box {
        // width: 713px;
        // height: 920px;
        width: 706px;
        height: 913px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        padding: 39px 20px 0 15px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 27px;
        }
        .text {
          // width: 646px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
          text-align: left;
          margin: 0 auto;
          margin-bottom: 36px;
        }
        .fd-img {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 21px;

          img {
            width: 332px;
            height: 252px;
          }
        }
        .tecenter {
          width: 666.66px;
          height: 377px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .slaughter-title {
        // width: 100vw;
        width: 1000px;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 57px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .slaughter-buttom {
        // width: 100vw;
        width: 1000px;
        height: 3245px;
        text-align: center;

        // padding: 0 21px;
        // box-sizing: border-box;
        margin-bottom: 57px;
        img {
          width: 710px;
          height: 3245px;
        }
      }
      .slaughter-buttom-box {
        // width: 710px;
        // min-height: 3245px;
        width: 707px;
        min-height: 3238px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        // padding-top: 25px;
        padding: 25px 13px 31px 13px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .title-mb {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 12px;
        }
        .text {
          // width: 230px;
          // width: 220px;
          width: 200px;
          // height: 187.5px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        // .text-1 {
        //   padding-right: 25px;
        //   box-sizing: border-box;
        // }
        // .text-2 {
        //   padding-left: 20px;
        //   box-sizing: border-box;
        // }
        .tupian-1 {
          width: 445px;
          height: 252px;
          // padding-left: 13.8px;
          // box-sizing: border-box;
        }
        .tupian-2 {
          width: 454px;
          height: 270px;
          // padding-right: 15px;
          // box-sizing: border-box;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 111px;
          .teleft {
            text-align: left;
          }
        }
      }
    }
    .dynamic {
      background: #6bd09e;
      padding-top: 45px;
      padding-bottom: 112px;
      // padding-right: 17px;
      box-sizing: border-box;
      overflow: hidden;
      .dynamic-box {
        width: 694px;
        min-height: 402px;
        margin: 0 auto;
        background: #ffffff;
        padding: 20px 34px 10px 20px;
        box-sizing: border-box;
        font-size: 20px;
      }
    }
    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      // line-height: 150px;
      width: 500px;
      height: 281.25px;
      // width: 100%;
      // height: 100%;
      text-align: center;
      background-color: #39a9ed;
    }
    .isWeixinOpen {
      // width: 100vw;
      width: 1000px;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .but-jia {
      // width: 100vw;
      width: 1000px;
      height: 91px;
    }
    .but-xiazai {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      // width: 100vw;
      width: 1000px;
      height: 91px;
      background: #f1ff10;
      border-top: 3px solid #32b76a;

      font-size: 41px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #048145;
      text-align: center;
      line-height: 91px;
    }
    .logoImg {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        width: 2.2rem;
        object-fit: contain;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6.7rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .bossBox {
      width: 8rem;
      height: 6.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      .verify {
        font-size: 0.53rem;
        color: #333;
        font-weight: 400;
        padding: 0.8rem 0 0.25rem 0;
      }
      .yanzheng {
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .song {
          width: 2.76rem;
          height: 1.15rem;
          background: #43d227;
          border-radius: 0.1rem;
          margin-top: 0.2rem;
          font-size: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fefefe;
          font-weight: 400;
        }
      }
      .sure {
        width: 4.6rem;
        height: 1.1rem;
        background: #43d227;
        font-size: 0.45rem;
        color: #fefefe;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        margin-top: 0.4rem;
        border-radius: 2rem;
      }
    }
    .van-cell-group {
      margin-top: 0.2rem;
      width: 7rem;
      .van-cell {
        height: 1.2rem;
        display: flex;
        align-items: center;
        background: #f2f2f2;
        border-radius: 0.15rem;
        overflow: hidden;
      }
      /deep/ .van-icon {
        font-size: 0.8rem;
        margin: 0 0.3rem 0 0.34rem;
      }

      /deep/ .van-field__body {
        font-size: 0.41rem;
      }
    }
    .cell {
      width: 4rem;
      /deep/ .van-icon {
        font-size: 0.65rem;
        margin: 0 0.3rem 0 0.41rem;
      }
    }
    .titile {
      font-size: 0.4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(#000, 0.4);
      padding: 0.2rem 0.5rem;
      color: #fff;
      border-radius: 0.2rem;
      z-index: 999999;
    }
    .isWeixinOpen {
      // width: 100vw;
      width: 1000px;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .van-dialog {
      width: 6rem;
      height: 3.5rem;
      /deep/ .van-hairline--top {
        height: 0.9rem;
        margin-top: 0.6rem;
      }
      /deep/ .van-button {
        height: 100%;
      }
      .titleInfo {
        margin: 0.4rem 0 0.1rem;
      }
      P {
        width: 100%;
        text-align: center;
        font-size: 0.34rem;
        margin-top: 0.1rem;
      }
    }
  }
}
</style>
